import React, {useEffect, useState} from 'react';
import { useParams} from "react-router-dom";
import './ExamResultEach.scss'
import axios from "axios";
import {setDocumentTitle} from "../Store/DocumentTitle";

const ExamResultEach = (callback, deps) => {
    const {id} = useParams()
    const [exam, setExam] = useState({})
    const [questions, setQuestions] = useState([])
    const formatter = (time) => {
        const isoDate = new Date(time);

        // Format date using toLocaleString
        return isoDate.toLocaleString('uz-UZ', {
            year: 'numeric',
            month: '2-digit',
            day: '2-digit',
            hour: 'numeric',
            minute: 'numeric',
            second: 'numeric'
        });
    }
   const getExam=()=>{
        axios.get(`https://api-lms.usat.uz/api/result/${id}/student/${sessionStorage.getItem('student_uid_id')}/`).then((res)=> {
            setDocumentTitle(res.data.exam.name)
           setExam(res.data)
        }).catch(()=>{
        })
    }
    const getQuestion = ()=>{
        axios.get(`https://api-lms.usat.uz/api/student-select-answers-get-profile/${id}/for/${sessionStorage.getItem('student_uid_id')}/`).then((res)=> {
            let tempArr = []
            // eslint-disable-next-line array-callback-return
            res.data.data.json_field.map((element) => {
                let model = {
                    id: element.id,
                    name: element.name,
                    answers: element.answers.map(answer => ({ ...answer, is_selected: false,is_true:false })),
                };
                tempArr.push(model)
            });
            setQuestions(tempArr)
            collectSelect(res.data.data.exam_select_answer,tempArr)
        }).catch(()=>{
        })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    const collectSelect = (select,question) => {
        const updatedQuestions = [...question];

        // eslint-disable-next-line array-callback-return
        select.map((pair) => {
            const { question: questionId, is_selected: answerId, true_answer: trueId } = pair;
            const selectedQuestionIndex = updatedQuestions.findIndex((q) => q.id === questionId);

            if (selectedQuestionIndex !== -1) {
                const selectedQuestion = { ...updatedQuestions[selectedQuestionIndex] };
                // eslint-disable-next-line array-callback-return
                selectedQuestion.answers.map((answer) => {
                    answer.is_selected = false;
                    answer.is_true = false;
                });

                const selectedAnswerIndex = selectedQuestion.answers.findIndex((a) => a.id === answerId);
                if (selectedAnswerIndex !== -1) {
                    selectedQuestion.answers[selectedAnswerIndex].is_selected = true;
                }

                const selectedAnswerTrueIndex = selectedQuestion.answers.findIndex((a) => a.id === trueId);
                if (selectedAnswerTrueIndex !== -1) {
                    selectedQuestion.answers[selectedAnswerTrueIndex].is_true = true;
                }

                updatedQuestions[selectedQuestionIndex] = selectedQuestion;
            }
        });

        setQuestions(updatedQuestions);
    };
    useEffect(()=>{
        getExam()
        getQuestion()
    },[])
    return (
        <div>




                            <div className="main-item-menu  ">
                                <div className="right" style={{width:'30% !important'}}>
                                    <div className="header">

                                    </div>
                                    <div>
                                        <div className="item">
                                            <div className="name">
                                                Imtihon
                                            </div>
                                            <div className="main-item">
                                                {exam.exam?.name}
                                            </div>
                                        </div>
                                        <div className="item">
                                            <div className="name">
                                                Talaba
                                            </div>
                                            <div className="main-item">
                                                {exam.student?.full_name}
                                            </div>
                                        </div>
                                        <div className="item">
                                            <div className="name">
                                                Guruh
                                            </div>
                                            <div className="main-item">
                                                {exam.group?.name}
                                            </div>
                                        </div>
                                        <div className="item">
                                            <div className="name">
                                                Boshlandi
                                            </div>
                                            <div className="main-item">
                                                {formatter(exam.begin_time)}
                                            </div>
                                        </div>
                                        <div className="item">
                                            <div className="name">
                                                Tugadi
                                            </div>
                                            <div className="main-item">
                                                {formatter(exam.end_time)}
                                            </div>
                                        </div>
                                        <div className="item">
                                            <div className="name">
                                                Natija
                                            </div>
                                            <div className="main-item">
                                                {exam.correct_answer}/{exam.total_count}
                                            </div>
                                        </div>
                                        <div className="item">
                                            <div className="name">
                                                Foiz
                                            </div>
                                            <div className="main-item">
                                                {exam.percentage}
                                            </div>
                                        </div>

                                    </div>
                                </div>
                                <div className="left" style={{width:'70%'}}>
                                    <div className="test_left">
                                        <div className="test_body">
                                            <div className="test_subject">
                                                {questions.map((question, q) => (
                                                    <div
                                                        className="test_question"
                                                        key={q}
                                                    >
                                                        <div className="test_question-title pt-1">
                                                            <div className="question-an">
                                                                <span dangerouslySetInnerHTML={{__html:question.name}}/>
                                                            </div>
                                                        </div>
                                                        <ul className="test_answers pb-1">
                                                            {question.answers.map((item, a) => (
                                                                <li
                                                                    key={a}
                                                                    className={item.is_selected && item.is_true ? 'test_answers-title active' : (item.is_selected && !item.is_true) ? 'test_answers-title wrong' : 'test_answers-title'}
                                                                >
                                                                    {item.is_selected && item.is_true ?
                                                                        <div className="mr-1">
                                                                            ✔
                                                                        </div> : (item.is_selected && !item.is_true) ?
                                                                            <div className="mr-1">
                                                                                ✖
                                                                            </div> : <></>
                                                                    }
                                                                    <div dangerouslySetInnerHTML={{__html:item.name}} className="answer-one"/>
                                                                </li>
                                                            ))}

                                                        </ul>
                                                    </div>
                                                ))}

                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>


        </div>
    )
        ;
};

export default ExamResultEach;