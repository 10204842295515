import React, {useEffect, useState} from 'react';
import {Button, Checkbox, Form, Input} from "antd";
import axios from "axios";
import './Profile.scss'
import {toast, ToastContainer} from "react-toastify";


const Profile = () => {
    const [form] = Form.useForm();
    const [formPass] = Form.useForm();
    const [componentDisabled, setComponentDisabled] = useState(true);
    const[user,setUser] = useState({})
    const getUser = () => {
        axios.get('https://api-lms.usat.uz/api/get/student/' + sessionStorage.getItem('studentId') + '/')
            .then((data) => {
                setUser(data.data)
                form.setFieldsValue(data.data)
            })
            .catch((error) => {
            })
            .finally(() => {
            });
    }
    const onFinish = (values) =>{
        axios.post('https://student.tfi.uz/rest/v1/account/update',{...values,"change_password": true
        },{
            headers:{
                'Authorization':'Bearer ' + sessionStorage.getItem('access')
            }
        }).then((res)=>{
            toast.success(res.data.data.message, {
                position: toast.POSITION.TOP_RIGHT,
            });
        }).catch((err)=>{
            toast.error(err.response.data.error, {
                position: toast.POSITION.TOP_RIGHT,
            });
        }).finally(()=>{
            formPass.resetFields()
        })
    }
    useEffect(() => {
        getUser()
    }, [])
    return (
        <div className='profile'>
            <ToastContainer/>

            <div className='forms'>
                <Form
                    disabled={true}
                    layout='vertical'
                    variant="filled"
                    form={form}
                    style={{
                        maxWidth: 500,
                    }}
                >
                    <div className='title'>Mening profilim</div>
                    <Form.Item
                        label="Ism"
                        name="first_name"
                        rules={[
                            {
                                required: true,
                                message: 'Please input!',
                            },
                        ]}
                    >
                        <Input size={'large'}/>
                    </Form.Item>
                    <Form.Item
                        label="Familiya"
                        name="second_name"
                        rules={[
                            {
                                required: true,
                                message: 'Please input!',
                            },
                        ]}
                    >
                        <Input size={'large'}/>
                    </Form.Item>
                    <Form.Item
                        label="Login"
                        name="student_id_number"
                        rules={[
                            {
                                required: true,
                                message: 'Please input!',
                            },
                        ]}
                    >
                        <Input size={'large'}/>
                    </Form.Item>
                    <Form.Item
                        label="Passport raqami"
                        name="passport_number"
                        rules={[
                            {
                                required: true,
                                message: 'Please input!',
                            },
                        ]}
                    >
                        <Input size={'large'}/>
                    </Form.Item>
                    <Form.Item
                        label="Email"
                        name="email"
                        rules={[
                            {
                                required: true,
                                message: 'Please input!',
                            },
                        ]}
                    >
                        <Input size={'large'}/>
                    </Form.Item>
                    <Form.Item
                        label="Telefon raqami"
                        name="phone"
                        rules={[
                            {
                                required: true,
                                message: 'Please input!',
                            },
                        ]}
                    >
                        <Input size={'large'}/>
                    </Form.Item>
                </Form>
                <div className='password'>

                    <Checkbox
                        checked={componentDisabled}
                        onChange={(e) => setComponentDisabled(e.target.checked)}
                    >
                        Parolni o'zgartirish
                    </Checkbox>
                    <Form
                        className='changing-pass'
                        layout={"vertical"}
                        form={formPass}
                        onFinish={onFinish}
                        style={{
                            maxWidth: 500,
                        }}
                        disabled={componentDisabled}

                    >
                        <Form.Item className="image" name="photo" label="">
                        </Form.Item>
                        <div className='main-inputs'>

                            <Form.Item
                                name="password"
                                label="Parol"
                                rules={[
                                    {
                                        required: true,
                                        message: 'Please input your password!',
                                    },
                                ]}
                                hasFeedback
                            >
                                <Input.Password size={"large"}/>
                            </Form.Item>

                            <Form.Item
                                name="confirmation"
                                label="Parol tasdig'i"
                                dependencies={['password']}
                                hasFeedback
                                rules={[
                                    {
                                        required: true,
                                        message: 'Please confirm your password!',
                                    },
                                    ({getFieldValue}) => ({
                                        validator(_, value) {
                                            if (!value || getFieldValue('password') === value) {
                                                return Promise.resolve();
                                            }
                                            return Promise.reject(new Error('The two passwords that you entered do not match!'));
                                        },
                                    }),
                                ]}
                            >
                                <Input.Password size={"large"}/>
                            </Form.Item>
                            <div className='submit-btn'>
                                <Button style={{width: '200px'}} size={"large"} type="primary" htmlType="submit">
                                    Saqlash
                                </Button>
                            </div>
                        </div>
                    </Form>
                </div>
            </div>
            <div>
                <div className='img'>
                    {/* eslint-disable-next-line jsx-a11y/img-redundant-alt */}
                    <img src={user?.image} alt='User image'/>
                </div>
            </div>

        </div>
    );
};

export default Profile;