import React, {useState, useEffect} from 'react';
import {Button, Form, Input, Modal, Segmented} from "antd";
import './FaceId.scss'
import {UserOutlined} from "@ant-design/icons";
import {getCameraStatus, setCameraStatus, setScreenshot} from "./faces/authSlice";
import {setFacenetMessage, setOutline} from "./faces/facenetSlice";
import {useDispatch, useSelector} from "react-redux";
import {Loader} from "./faces/Loader";
import {Camera} from "./faces/Camera";
import {useNavigate} from "react-router-dom";
import {toast, ToastContainer} from "react-toastify";

const FaceId = () => {
    const navigation = useNavigate()
    const [facesMatched] = useState(null);
    const [value, setValue] = useState('Face id orqali kirish');
    const dispatch = useDispatch()
    const cameraStatus = useSelector(getCameraStatus)
    const handleCameraClosing = () => {
        dispatch(setCameraStatus('closed'))
        dispatch(setScreenshot(null))
        dispatch(setOutline('#ddd'))
        dispatch(setFacenetMessage('Place the face in the oval.'))
    }
    const handleCameraOpening = () => {
        dispatch(setCameraStatus('opened'))
        dispatch(setScreenshot(null))
        dispatch(setOutline('#ddd'))
        dispatch(setFacenetMessage('Place the face in the oval.'))
    }
    const setWidth = () => {
    };


    const changeRoute = (value) => {
        setValue(value)
        if (value === 'Kirish') {
            navigation('/login')

        } else {
            navigation('/faceId')


        }
    }
    const onFinish = (e) => {
        console.log(e)
        localStorage.setItem('student',e?.stuednt_id)
        if (cameraStatus === 'closed') {
            handleCameraOpening()
        } else {
            handleCameraClosing()
        }
    }
    useEffect(() => {

        setWidth();
        window.addEventListener('resize', setWidth);
        return () => {
            window.removeEventListener('resize', setWidth);
        };
    }, [facesMatched]);

    return (

        <div className="auth">
            <ToastContainer/>
        <Loader/>
        <div className="auth__content">
            <Segmented className='segment' size={"large"} style={{background: '#1677ff'}}
                       options={['Kirish', 'Face id orqali kirish']} value={value} onChange={changeRoute}/>
            <div className="auth__wrap">
                <div className="auth__logo">
                    <img src="/images/miniLogo.svg" width={120} alt="" className="mb-5"/>
                </div>
                <div className='title-otm'>
                    Fan va texnologiyalar universiteti
                </div>
                <div className='subtitle-otm'>
                     ta'lim tizimi
                </div>
                <Form
                    className='basic-form'
                    layout={'vertical'}
                    name="basic"
                    labelCol={{
                        span: 8,
                    }}
                    style={{
                        maxWidth: 600,
                    }}
                    initialValues={{
                        remember: true,
                    }}
                    onFinish={onFinish}
                    // onFinishFailed={onFinishFailed}
                    autoComplete="off"
                >
                    <Form.Item
                        style={{width: '100%'}}
                        label="ID raqam"
                        name="stuednt_id"
                        rules={[{
                            required: true, message: 'Iltimos id raqamingizni yozing!',
                        },]}
                    >
                        <Input suffix={<UserOutlined/>} size='large'/>
                    </Form.Item>
                    <Form.Item style={{width: '100%'}}>
                        <Button style={{width: '100%'}} size={"large"} type="primary" htmlType="submit">
                            Kirish
                        </Button>
                    </Form.Item>
                </Form>
                <Modal
                    className='modalFace'
                    closable={false}
                    footer='none'
                    title=""
                    header={'none'}
                    open={cameraStatus !== 'closed'}
                >
                    <Camera/>

                </Modal>
            </div>
        </div>
        <div className="auth__photo">
            <img src='/images/miniLogo.svg' width={200} alt='Logo TFI' draggable={"false"} style={{userSelect: 'none'}}/>
        </div>
    </div>);
};

export default FaceId;