// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.resources-list .active {
  width: 20px;
  height: 20px;
  background: rgba(0, 0, 0, 0.12);
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 auto;
}
.resources-list .active.true {
  background: #28C76F;
  animation: pulse-animation 2s infinite;
}
@keyframes pulse-animation {
  0% {
    box-shadow: 0 0 0 0px rgba(0, 0, 0, 0.2);
  }
  100% {
    box-shadow: 0 0 0 20px rgba(0, 0, 0, 0);
  }
}`, "",{"version":3,"sources":["webpack://./src/potoc/ResourcesList.scss"],"names":[],"mappings":"AAEA;EACE,WAAA;EACA,YAAA;EACA,+BAAA;EACA,kBAAA;EACA,aAAA;EACA,mBAAA;EACA,uBAAA;EACA,cAAA;AADF;AAEE;EACE,mBAAA;EACA,sCAAA;AAAJ;AAGA;EACE;IACE,wCAAA;EADF;EAGA;IACE,uCAAA;EADF;AACF","sourcesContent":[".resources-list{\r\n\r\n.active{\r\n  width: 20px;\r\n  height: 20px;\r\n  background: rgba(0, 0, 0, 0.12);\r\n  border-radius: 50%;\r\n  display: flex;\r\n  align-items: center;\r\n  justify-content: center;\r\n  margin: 0 auto;\r\n  &.true{\r\n    background: #28C76F ;\r\n    animation: pulse-animation 2s infinite;\r\n  }\r\n}\r\n@keyframes pulse-animation {\r\n  0% {\r\n    box-shadow: 0 0 0 0px rgba(0, 0, 0, 0.2);\r\n  }\r\n  100% {\r\n    box-shadow: 0 0 0 20px rgba(0, 0, 0, 0);\r\n  }\r\n}\r\n}\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
