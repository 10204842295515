import React, {createContext, useContext, useEffect, useState} from "react";
import axios from "axios";
import {useAppContext} from "../Store/Store";
const AuthContext = createContext()
const AuthProvider = ({children}) => {
    const [user, setUser] = useState(null);
    const {  setInfo } = useAppContext();
    const [loading, setLoading] = useState(true);


    const getUser = () => {
        axios.get(`https://api-lms.usat.uz/api/get/student/${sessionStorage.getItem('studentId')}/`, {
            headers: {
                // Authorization: `Bearer ${cookies.access}`
            }
        }).then((res) => {
            setInfo(res.data)
            sessionStorage.setItem('groupId',res.data.group.id)
            setUser(res.data);
        }).catch((error) => {
            setLoading(false);
        });
    };

    useEffect(() => {
        getUser();

    },[]);

    const logout = () => {
        setUser(null);
    };

    return (
        <AuthContext.Provider value={{user, loading, getUser, logout,setInfo}}>
            {children}
        </AuthContext.Provider>);
};

const useAuth = () => {
    const context = useContext(AuthContext);
    if (!context) {
        throw new Error("useAuth must be used within an AuthProvider");
    }
    return context;
};

export {AuthProvider, useAuth, AuthContext};
