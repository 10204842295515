import React, {useEffect, useState} from 'react';
import 'video-react/dist/video-react.css';
import './UplodedResources.scss'
import {Button, Drawer, Spin, Table} from "antd";
import {
    CloudDownloadOutlined,
    PauseCircleOutlined,
    PlayCircleOutlined,
    SyncOutlined
} from "@ant-design/icons";
import axios from "axios";
import {useParams} from "react-router-dom";
import Plyr from "plyr-react";
import 'plyr-react/plyr.css';
import {setDocumentTitle} from "../../Store/DocumentTitle";




const UploadedResuorces = () => {
    const {id} = useParams()
    const [open, setOpen] = useState(false)
    const [videos, setVideos] = useState([])
    const [files, setFiles] = useState([])
    const [loading,setLoading] = useState(false)
    const [currentVideo, setCurrentVideo] = useState(
        'https://api-lms.usat.uz/api/stream/?path=' + (localStorage.getItem('currentVideoFile') || '')
    );
    const columns = [
        {
            title: 'Qo\'llanma nomi',
            dataIndex: 'name',
            key: 'name',
        },

        {
            title: 'Yuklash',
            key: 'tags',
            dataIndex: 'tags',
            width:'8%',
            render: (_, record) => (
                <>
                    <Button onClick={()=>download(record.file_file)}  type='primary' size={"large"}>
                        <CloudDownloadOutlined />
                    </Button>
                </>
            ),
        },
    ];
    const onClose = () => {
        setOpen(false)
    }
    const getResources = () => {
        setLoading(true)
        axios.get(`https://api-lms.usat.uz/api/student/content/topic/one/?topic_id=${id}`)
            .then((res) => {
                setDocumentTitle(res.data.results.name)
                setVideos(res.data.results.topic_videos)
                setFiles(res.data.results.topic_files)
                setLoading(false)
            })
            .catch((err) => {
                setLoading(false)
            });
    }

    const perform = (id) => {
        let foundVideo = videos.find((item) => item.id === id);
        if (foundVideo) {
            setCurrentVideo('https://api-lms.usat.uz/api/stream/?path=' + foundVideo.vide_file);
            localStorage.setItem('currentVideoFile', foundVideo.vide_file);
            localStorage.setItem('currentVideoId', foundVideo.id);
        }
    }
    const next = (id) => {
        let index = videos.findIndex((item) => item.id === id);
        let foundVideo = -1;

        if (index !== -1) {
            foundVideo = (index + 1) % videos.length;
        } else {
            foundVideo = 0;
        }
        setCurrentVideo('https://api-lms.usat.uz/api/stream/?path=' + videos[foundVideo].vide_file);
        localStorage.setItem('currentVideoFile', videos[foundVideo].vide_file);
        localStorage.setItem('currentVideoId', videos[foundVideo].id);
    };
    const download = (file) => {
        axios
            .get(`https://api-lms.usat.uz${file}`, { responseType: 'blob' }) // Set responseType to 'blob'
            .then((res) => {
                handleDownloadResponse(res, file);
            })
            .catch((err) => {
                console.error(err);
            });
    };

    const handleDownloadResponse = (response, file) => {
        const blob = new Blob([response.data], {
            type: response.headers['content-type'],
        });
        const link = document.createElement('a');
        link.href = window.URL.createObjectURL(blob);
        const filename = file.split('/').pop();
        link.download = filename;
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
        window.URL.revokeObjectURL(link.href);
    };
    useEffect(() => {
        getResources()
    }, []);
    useEffect(() => {
        const storedVideoId = localStorage.getItem('currentVideoId');
        if (storedVideoId) {
            perform(storedVideoId);
        }
        else {
            perform(videos[0]?.id)
        }
    }, [videos]);
    return (
        <Spin wrapperClassName={'spinner'} spinning={loading} size={"large"}>
            {
                videos?.length===0&&files?.length===0?<div style={{display:'flex',alignItems:'center',justifyContent:'center'}}>
                    Resourselar mavjud emas
                </div>
                :<></>
            }
        <div >
            {videos?.length===0?<></>: <>
                <div className='buttons-resource'>
                    <Button onClick={() => setOpen(true)} type='primary' size='large'>
                        Barcha videolar
                    </Button>
                    <Button onClick={() => next(localStorage.getItem('currentVideoId'))} type='primary' size='large'>
                        Keyingisi
                    </Button>
                </div>
                <Drawer
                    title="Barcha videolar"
                    placement='right'
                    closable={false}
                    onClose={onClose}
                    open={open}
                    key='right'
                >
                    <div className='videos'>
                        {videos.map((video) => (
                            <div onClick={() => perform(video.id)} key={video.id} className='video-item'>
                                <div style={{display: 'flex'}}>
                                    <div className='icon'>
                                        {localStorage.getItem('currentVideoId') === video.id ?
                                            <PauseCircleOutlined/>
                                            :
                                            <PlayCircleOutlined/>

                                        }
                                    </div>
                                    <div style={{marginLeft: '5px'}} className='video-name'>
                                        {video.name}
                                    </div>
                                </div>
                                <div className='onPlaying'>
                                    {localStorage.getItem('currentVideoId') === video.id ?
                                        <SyncOutlined spin/>
                                        :
                                        <></>
                                    }
                                </div>
                            </div>

                        ))

                        }
                    </div>
                </Drawer>
                <div className='player'>
                    <Plyr
                        source={{
                            type: 'video',
                            sources: [
                                {
                                    src: currentVideo,
                                    type: 'video/mp4',
                                },
                            ],
                        }}
                    />
                </div>
            </>

            }

            {
                files?.length === 0 ? <></> :
                    <Table style={{marginTop: '5px'}} columns={columns} pagination={false} dataSource={files}/>

            }


        </div>
        </Spin>
    );
};

export default UploadedResuorces;