import Home from "./home/home";
import {AuthProvider} from "./auth/AuthProvider";
import {Route, Routes} from "react-router-dom";
import Login from "./login/login";
import {RequireAuth} from "./auth/RequireAuth";
import React from "react";
import FaceId from "./FaceID/FaceId";
import Profile from "./Profile/Profile";
import Resources from "./Resources/Resources";
import ResourcesList from "./Resources/Resources-list/ResourcesList";
import UploadedResuorces from "./Resources/Uploaded-resurces/UploadedResuorces";
import Task from "./Task/Task";
import TaskList from "./Task/Task-list/Task-list";
import Exam from "./Exam/Exam";
import ExamStart from "./Exam/ExamStart/ExamStart";
import ExamResult from "./ExamResult/ExamResult";
import ExamResultEach from "./ExamResult/ExamResultEach";
import PrivateInfo from "./PrivateInfo/PrivateInfo";
import Potoc from "./potoc/potoc";

function App() {
    return (
        <div className="App">
            <AuthProvider>
                <Routes>

                    <Route path='/login' element={<Login/>}/>
                    <Route path='/faceId' element={<FaceId/>}/>
                    <Route
                        path='/'
                        element={
                            <RequireAuth>
                                <Home/>
                            </RequireAuth>
                        }
                    >
                        <Route path='/profile' element={<Profile/>}/>
                        <Route path='/' element={<Resources/>}/>
                        <Route path='/resources-list/:id' element={<ResourcesList/>}/>
                        <Route path='/uploaded-resources/:id' element={<UploadedResuorces/>}/>
                        <Route path='/task/:id' element={<Task/>}/>
                        <Route path='/task-list/:id' element={<TaskList/>}/>
                        <Route path='/exams' element={<Exam/>}/>
                        <Route path='/result' element={<ExamResult/>}/>
                        <Route path='/result/each/:id' element={<ExamResultEach/>}/>
                        <Route path='/privateinfo' element={<PrivateInfo/>}/>
                        <Route path='/potoc' element={<Potoc/>}/>

                    </Route>
                    <Route path='/exam-started/:id' element={
                        <RequireAuth> <ExamStart/></RequireAuth>
                    }/>

                </Routes>
            </AuthProvider>
        </div>
    );
}

export default App;
