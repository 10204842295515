import React, {useEffect, useState} from 'react';
import './PrivateInfo.scss'
import axios from "axios";
const PrivateInfo = () => {
    const [user,setUser] = useState({})
    const getUser = () => {
        axios.get('https://api-lms.usat.uz/api/get/student/' + sessionStorage.getItem('studentId')+ '/')
            .then((data) => {
                setUser(data.data)
            })
            .catch((error) => {
            })
            .finally(() => {
            });
    }
    useEffect(()=>{
        getUser()
    },[])
    return (
        <div>
            <div className="card">
                <div className="box">
                    <div className="box-header pa-10">
                        <h3 className="box-title">Passport ma'lumoti</h3>
                    </div>
                    <div className="box-body">
                        <div className="table-block">
                            <table>
                                <tbody>
                                <tr>
                                    <th>Fuqarolik</th>
                                    <td>{user?.country?.name}</td>
                                </tr>
                                <tr>
                                    <th>Pasport raqami</th>
                                    <td>{user?.passport_number}</td>
                                </tr>
                                <tr>
                                    <th>JSHSHIR-kod</th>
                                    <td>{user?.passport_pin}</td>
                                </tr>
                                <tr>
                                    <th>Familiya</th>
                                    <td>{user?.second_name}</td>
                                </tr>
                                <tr>
                                    <th>Ismi</th>
                                    <td>{user?.first_name}</td>
                                </tr>
                                <tr>
                                    <th>Otasining ismi º</th>
                                    <td>{user?.third_name}</td>
                                </tr>

                                <tr>
                                    <th>Jins</th>
                                    <td>{user?.gender?.name}</td>
                                </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
                <div className="box">
                    <div className="box-header pa-10">
                        <h3 className="box-title">Doimiy manzil ma'lumotlari</h3>
                    </div>
                    <div className="box-body">
                        <div className="table-block">
                            <table>
                                <tbody>
                                <tr>
                                    <th>Davlat</th>
                                    <td>{user?.country?.name}</td>
                                </tr>
                                <tr>
                                    <th>Viloyat</th>
                                    <td>{user?.province?.name}</td>
                                </tr>
                                <tr>
                                    <th>Tuman</th>
                                    <td>{user?.district?.name}</td>
                                </tr>
                                <tr>
                                    <th>Uy manzili</th>
                                    <td>{user?.address}</td>
                                </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
                <div className="box">
                    <div className="box-header pa-10">
                        <h3 className="box-title">Joriy manzil ma'lumotlari</h3>
                    </div>
                    <div className="box-body">
                        <div className="table-block">
                            <table>
                                <tbody>
                                <tr>
                                    <th>Yashash joyi</th>
                                    <td>{user?.accommodation?.name}</td>
                                </tr>
                                <tr>
                                    <th>Joriy viloyat</th>
                                    <td>{user?.province?.name}</td>
                                </tr>
                                <tr>
                                    <th>Joriy tuman</th>
                                    <td>{user?.district?.name}</td>
                                </tr>
                                <tr>
                                    <th>Joriy manzil</th>
                                    <td>{user?.address}</td>
                                </tr>
                                <tr>
                                    <th>Email</th>
                                    <td>{user?.email}</td>
                                </tr>
                                <tr>
                                    <th>Talaba telefoni (+998 xx ххх-хх-хх)</th>
                                    <td>{user?.phone}</td>
                                </tr>
                                <tr>
                                    <th>Ota-onasi telefoni</th>
                                    <td></td>
                                </tr>
                                <tr>
                                    <th>Mas'ul shaxs telefoni</th>
                                    <td></td>
                                </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
                <div className="box">
                    <div className="box-header pa-10">
                        <h3 className="box-title">Ta’lim ma’lumoti</h3>
                    </div>
                    <div className="box-body">
                        <div className="table-block">
                            <table>
                                <tbody>

                                <tr>
                                    <th>Fakultet</th>
                                    <td>{user?.faculty?.name}</td>
                                </tr>
                                <tr>
                                    <th>Kurs</th>
                                    <td>{user?.level?.name}</td>
                                </tr>
                                <tr>
                                    <th>Guruh</th>
                                    <td>{user?.group?.name}</td>
                                </tr>
                                <tr>
                                    <th>To‘lov shakli</th>
                                    <td>{user?.paymentForm?.name}</td>
                                </tr>
                                <tr>
                                    <th>Ta’lim turi</th>
                                    <td>{user?.educationType?.name}</td>
                                </tr>
                                <tr>
                                    <th>Ta’lim shakli</th>
                                    <td>{user?.educationForm?.name}</td>
                                </tr>
                                <tr>
                                    <th>O‘quv yili</th>
                                    <td>{user?.semester?.education_year?.name}</td>
                                </tr>
                                <tr>
                                    <th>Semestr</th>
                                    <td>{user?.semester?.name}</td>
                                </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default PrivateInfo;