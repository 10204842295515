// eslint-disable-next-line no-unused-vars
import React, {useCallback, useEffect, useRef, useState} from 'react';
import axios from "axios";
import {useNavigate, useParams} from "react-router-dom";
import {toast, ToastContainer} from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';
import './ExamStart.scss'
import {Button, Modal, Radio} from "antd";
import CustomCountdown from "../../Store/CustomCountdown";
import {setDocumentTitle} from "../../Store/DocumentTitle";
import {useProctoring} from "../../hooks/useProctoring";


const ExamStart = () => {
    const [questions, setQuestions] = useState([]);
    const [select, setSelect] = useState([]);
    const {id} = useParams();
    const [ipAddress, setIpAddress] = useState();
    const [answerLabels] = useState(["A", "B", "C", "D", "E", "F", "G", "H"]);
    const [testTimer, setTestTimer] = useState(5);
    const questionRefs = useRef([]);
    const [loading, setLoading] = useState(false);
    const navigate = useNavigate();
    const [isStartStream, setIsStartStream] = useState(false);
    const [screenStream, setScreenStream] = useState({});
    const [view, setView] = useState(false);
    const [isBeginExam, setIsBeginExam] = useState(false)
    const [isCancled, setIsCancled] = useState(false)
    const { fullScreen, tabFocus } = useProctoring({
        forceFullScreen: true,
        preventTabSwitch: true,
        preventContextMenu: true,
        preventUserSelection: true,
        preventCopy: true,
    })
    const getExamDetail = useCallback(async (ip) => {
        try {
            const response = await axios.get(`https://api-lms.usat.uz/api/exam-detail/${id}`);
            setDocumentTitle(response.data.name)
            resultCreate(response.data, ip);
        } catch (error) {
            console.log(error)
        }
    }, [id]);

    const resultCreate = useCallback(async (data, ip) => {
        setLoading(true);
        try {
            const result = {
                exam: id,
                student: sessionStorage.getItem('student_uid_id'),
                group: sessionStorage.getItem('groupId'),
                ip_address: ip,
                attempts: data.attempts,
                total_count: data.total_count,
                max_score: data.max_score,
                is_start: true,
                exam_time: data.exam_time,
            };
            const response = await axios.post(`https://api-lms.usat.uz/api/result/create`, result);
            setTestTimer(Math.abs(response.data.exam_time_second));
            getExamTest(ip);
        } catch (error) {
            console.log(error)
        } finally {
            setLoading(false);
        }
    }, [id]);

    const getExamTest = useCallback(async (ip) => {
        try {
            const response = await axios.post(`https://api-lms.usat.uz/api/test/begin/`, {
                exam: id,
                ip_address: ip,
                student: sessionStorage.getItem('student_uid_id'),
            });
            const temp = response.data.map(element => ({
                id: element.id,
                name: element.name,
                answers: element.answers.map(answer => ({
                    ...answer,
                    is_selected: false,
                })),
                is_selected: false,
            }));
            setQuestions(temp);
            setIsBeginExam(true)

            getDefault();


        } catch (error) {
        }
    }, [id]);
    const getDefault = useCallback(() => {
        axios.get(`https://api-lms.usat.uz/api/student-select-answers-get/${id}/for/${sessionStorage.getItem('student_uid_id')}/`)
            .then((res) => {
                setSelect(res.data.data);
            })
            .catch((err) => {
            });
    }, [id]);
    const collectSelect = useCallback((selected) => {
        setQuestions((prevQuestions) => {
            return prevQuestions.map((selectedQuestion) => {
                let updatedQuestion = {...selectedQuestion}; // Create a new object to avoid mutations
                const pair = Array.isArray(selected) ? selected.find((p) => p.question === selectedQuestion.id) : null;
                if (pair) {
                    const answerId = pair.is_selected;
                    updatedQuestion.is_selected = true;
                    updatedQuestion.answers = updatedQuestion.answers.map((answer) => ({
                        ...answer,
                        is_selected: answer.id === answerId,
                    }));
                } else {
                    updatedQuestion.is_selected = false;
                    updatedQuestion.answers = updatedQuestion.answers.map((answer) => ({
                        ...answer,
                        is_selected: false,
                    }));
                }
                return updatedQuestion;
            });
        });
    }, []);
    const takeScreenShot = async () => {
        try {
            if (screenStream) {
                const track = screenStream.getVideoTracks()[0];
                const imageCapture = new ImageCapture(track);
                const bitmap = await imageCapture.grabFrame();
                const canvas = document.createElement('canvas');
                canvas.width = bitmap.width;
                canvas.height = bitmap.height;
                const context = canvas.getContext('2d');
                context.drawImage(bitmap, 0, 0, bitmap.width, bitmap.height);
                const image = canvas.toDataURL('image/jpeg');
                const res = await fetch(image);
                const clonedRes = res.clone();
                const blob = await clonedRes.blob();
                const file = new File([blob], 'image.jpg', { type: 'image/jpeg' });
                const reader = new FileReader();
                reader.readAsDataURL(blob);
                reader.onloadend = () => {
                    const base64Data = reader.result; // Get the base64 encoded data
                    const formData=new FormData()
                    formData.append('student',sessionStorage.getItem('student_uid_id'))
                    formData.append('exam',id)
                    formData.append('image',file)
                    axios.post('https://api-lms.usat.uz/api/autoproctor/create/',formData).then((res)=>
                    {
                        if(res?.data?.message){
                           toast.error(res?.data?.message)
                            if (res?.data?.is_active===false){
                                finishTest()
                            }
                        }

                    }).catch((err)=>{
                        console.log(err)
                    })
                    console.log(base64Data)
                };
            } else {
            }
        } catch (error) {
            console.error('Error capturing screenshot:', error);
        }
    };

    const finishTest = useCallback(() => {
        axios.get(`https://api-lms.usat.uz/api/exam-finish/${id}/finish/${sessionStorage.getItem('student_uid_id')}/`)
            .then(() => {
                navigate('/exams');
            })
            .catch((err) => {
                console.log(err)
            });
    }, [id, navigate]);

    const handleBeforeUnload = (event) => {
        const data = {
            exam: id,
            student: sessionStorage.getItem('student_uid_id'),
        };
        const blob = new Blob([JSON.stringify(data)], {
            type: "application/json",
        });
        navigator.sendBeacon("https://api-lms.usat.uz/api/check-logout/", blob);
    };
    const scrollToQuestion = (index) => {
        const testElement = questionRefs.current[index];

        if (testElement) {
            testElement.scrollIntoView({behavior: "smooth"});
        }

    };
    const selectAnswer = (questionId, answerId) => {
        axios.patch(`https://api-lms.usat.uz/api/student-exam-answers/${id}/for/${sessionStorage.getItem('student_uid_id')}/`, {
            question: questionId,
            is_selected: answerId,
            ip_address: ipAddress,
        })
            .then((res) => {
                collectSelect(res.data)
            })
            .catch((err) => {
                console.log(err)
            })
    }
    const showModal = () => {
        setView(prevState => !prevState)
    }
    const closeModal = () => {
        setView(prevState => !prevState)
    }
    const showErr = () => {
        setIsCancled(true)
    }
    const startScreenSharing = async () => {
        if (!isStartStream) {
            const displayMediaOptions = {
                video: {
                    mediaSource: 'screen',
                    displaySurface: "monitor"
                }
            };
            const successCallback = success => {
                success.addEventListener('inactive', () => {
                    console.log('test')
                    showErr()
                    setIsStartStream(false);
                });
                setIsStartStream(true); // Set isStartStream to true when screen sharing starts
                setScreenStream(success);
                fetchIpAddress()
            };
            const errorCallback = error => {
                showErr()
                console.error('Error accessing screen:', error.message || error);
            };
            if (navigator.mediaDevices.getDisplayMedia) {
                navigator.mediaDevices.getDisplayMedia(displayMediaOptions)
                    .then(successCallback)
                    .catch(errorCallback);
            } else if (navigator.getDisplayMedia) {
                navigator.getDisplayMedia(displayMediaOptions)
                    .then(successCallback)
                    .catch(errorCallback);
            } else {
                console.error('getDisplayMedia is not supported in this browser');
            }
        }
    };
    useEffect(() => {
        const handleBeforeUnload = (event) => {
            // Cancel the default behavior of the event
            event.preventDefault();
           console.log(event)
        };
        window.addEventListener('beforeunload', handleBeforeUnload);
        return () => {
            window.removeEventListener('beforeunload', handleBeforeUnload);
        };
    }, []);

    useEffect(() => {
        if(tabFocus.status===false){
            setTimeout(() => {
                takeScreenShot();
            }, 120);
        }

    }, [tabFocus.status]);
    useEffect(() => {
        startScreenSharing();
        window.addEventListener("beforeunload", handleBeforeUnload);
        return () => {
            handleBeforeUnload()
            window.removeEventListener("beforeunload", handleBeforeUnload);
        };
    }, []);
    const fetchIpAddress = async () => {
        try {
            const response = await axios.get('https://api-lms.usat.uz/api/get-ip-address/');
            setIpAddress(response.data.client_ip_address.split(',')[0]);
            getExamDetail(response.data.client_ip_address.split(',')[0]);
        } catch (error) {
        }
    };
    useEffect(() => {
        collectSelect(select);
    }, [select]);
    useEffect(() => {
        const handleWindowFocus = () => {
        };
        const handleWindowBlur = () => {
        };
        window.addEventListener('focus', handleWindowFocus);
        window.addEventListener('blur', handleWindowBlur);
        return () => {
            window.removeEventListener('focus', handleWindowFocus);
            window.removeEventListener('blur', handleWindowBlur);
        };
    }, []);

    return (
        <div style={{padding: '20px'}}>
            <ToastContainer/>
            <div className="test ">
                <div className="test_left">
                    <canvas style={{display: 'none'}} width="500" height="500" id="fake"></canvas>
                    <video style={{display: 'none'}} width="640" height="480" autoPlay></video>
                    <canvas style={{display: 'none'}} width="640" height="480"></canvas>
                    {/* eslint-disable-next-line jsx-a11y/img-redundant-alt */}
                    <img style={{display: 'none'}} id="faceImage" alt="Face Image"/>
                    <div className="test_body">
                        <div className="test_subject">
                            {questions?.map((question, q) => (
                                <div
                                    key={q} ref={(el) => (questionRefs.current[q] = el)}
                                    className="test_question"
                                >
                                    <div className="test_question-title pt-1 flex">
                                        <div style={{display: 'flex'}}>
                                            <span>{q + 1}.</span>
                                            <span dangerouslySetInnerHTML={{__html: question.name}}/>
                                        </div>
                                    </div>
                                    <ul className="test_answers pb-1">
                                        {question.answers?.map((item, a) => (
                                            <li
                                                onClick={() => selectAnswer(question.id, item.id)}
                                                className={item.is_selected ? 'test_answers-title active' : 'test_answers-title'}>
                                                <Radio checked={item.is_selected} value={item.is_selected}>
                                                    <div style={{display: 'flex', alignItems: 'center'}}>
                                                        <p className="mr-1">
                                                            {answerLabels[a] + ")"}
                                                        </p>
                                                        <p>
                                                            <span dangerouslySetInnerHTML={{__html: item.name}}/>
                                                        </p>
                                                    </div>
                                                </Radio>

                                            </li>
                                        ))

                                        }

                                    </ul>
                                </div>
                            ))

                            }

                        </div>

                    </div>
                </div>
                {isBeginExam ?
                    <div className="test_right">
                        <div className="test_pagination-top">
          <span

          >
              {loading ? <></> : <>
                  <CustomCountdown isStart={isBeginExam} duration={parseInt(testTimer)} onFinish={finishTest}/>
              </>

              }
          </span>
                            <Button type='primary' onClick={() => navigate('/exams')}>
                                Chiqish
                            </Button>
                        </div>
                        <ul className="test_pagination">
                            {questions?.map((question, index) => (
                                <li
                                    onClick={() => scrollToQuestion(index)}
                                    className={question.is_selected ? 'test_pagination-item active' : 'test_pagination-item'}>
                                    {index + 1}
                                </li>
                            ))

                            }

                        </ul>
                        <div className="test_pagination-bottom">
                            <Button type='danger' size="large" onClick={showModal}>
                                Yakunlash
                            </Button>
                        </div>
                    </div>
                    : ""
                }
            </div>
            <Modal centered width={300} style={{width: '200px !important'}} title='Imtihonni yakunlamoqchimisiz?'
                   footer={null} open={view} onCancel={closeModal}>
                <div style={{display: 'flex', gap: '5px', width: '223px'}}>
                    <Button size={"large"} style={{width: '50%'}} type={"primary"} onClick={finishTest}>Ha</Button>
                    <Button size={"large"} style={{width: '50%'}} danger type='primary'
                            onClick={closeModal}>Yo'q</Button>
                </div>
            </Modal>
            <Modal centered title='Imtihonni boshlash'
                   open={isCancled} footer={null}>
                <div style={{display:'flex',flexDirection:'column'}}>
                    <div>
                        Imtihonni boshlash uchun autoproktoringga ruxsat berishingiz shart!!
                    </div>
                    <Button size={"large"} style={{width:'100%'}} onClick={() => window.location.reload()}>Sahifani yangilash</Button>
                </div>

            </Modal>
            <Modal centered title='Imtihonni boshlamoqchimisiz?'
                   open={fullScreen.status === 'off'} footer={null}>
                <div style={{display:'flex',flexDirection:'column'}}>
                    <div>
                        Imtihonni boshlash (davom ettirish) uchun brauser oynasi to'liq shaklda bo'lishi kerak.
                        Va siz qilayotgan har bir harakat yozib olinadi va saqlanadi. Agar siz nojoya harakat qildadigan
                        bo'lsangiz ya'ni boshq manbalar (telegam,brauserning boshqa oynasi yoki h.k)
                        imtihondan avtomatik tarzda chetlashtirilasiz. Vaqt ketyabdi!!
                    </div>
                    <div>
                        <Button size={"large"} style={{width:'100%'}} onClick={() => fullScreen.trigger()}>Barcha shartlarga roziman</Button>
                    </div>
                </div>

            </Modal>

        </div>
    )

};

export default React.memo(ExamStart);