import React, {useCallback, useEffect, useState} from 'react';
import {Button, Table} from "antd";
import axios from "axios";
import { useNavigate} from "react-router-dom";
import {setDocumentTitle} from "../Store/DocumentTitle";

const ExamResult = (callback, deps) => {
    const [result, setResult] = useState([])
    const navigate = useNavigate()
    const columns = [
        {
            title: 'Imtihon nomi',
            dataIndex: 'name',
            key: 'name',
            render: (_, record) => (
                <p>{record.exam.name}</p>
            ),
        },
        {
            title: 'Max. ball',
            width: '10%',
            render: (_, record) => (
                <p>
                    {record.exam.max_score}
                </p>
            ),
        },
        {
            title: 'Boshlanish',
            width: '12%',
            render: (_, record) => (
                <p>
                    {formatter(record.exam.begin_time)}
                </p>
            ),
        },
        {
            title: 'Tugash',
            width: '12%',
            render: (_, record) => (
                <p>
                    {formatter(record.exam.end_time)}
                </p>
            ),
        },
        {
            title: 'Vaqt',
            width: '5%',
            render: (_, record) => (
                <p>
                    {record.exam.exam_time}
                </p>
            ),
        },
        {
            title: 'Natija',
            width: '5%',
            render: (_, record) => (
                <p>
                   <Button onClick={()=>navigate(`/result/each/${record.exam.id}`)} type={"primary"}>
                       Natija
                   </Button>
                </p>
            ),
        },
    ];
    // eslint-disable-next-line react-hooks/exhaustive-deps
    const getResults = useCallback(() => {
// eslint-disable-next-line no-template-curly-in-string
        axios.get(`https://api-lms.usat.uz/api/exam-list-finish/${sessionStorage.getItem('student_uid_id')}`).then((res) => {
            setResult(res.data)
        }).catch((err) => {

        })

    })
    const formatter = (time) => {
        const isoDate = new Date(time);

        // Format date using toLocaleString
        return isoDate.toLocaleString('uz-UZ', {
            year: 'numeric',
            month: '2-digit',
            day: '2-digit',
            hour: 'numeric',
            minute: 'numeric',
            second: 'numeric'
        });
    }
    useEffect(()=>{
        setDocumentTitle('Natijalar')
        getResults()
    },[])
    return (
        <div>


            <Table scroll={{
                x: 1000
            }} columns={columns} pagination={false} dataSource={result}/>
        </div>
    );
};

export default React.memo(ExamResult);