import React, {useEffect, useState} from 'react';
import {useNavigate, useParams} from "react-router-dom";
import {Button, Table} from "antd";
import axios from "axios";
import {setDocumentTitle} from "../Store/DocumentTitle";


const Task = () => {
    const {id} = useParams()
    const [subjects,setSubjects] = useState([])
    const navigate = useNavigate();
    const columns = [
        {
            title: 'Nomi',
            dataIndex: 'name',
            key: 'name',
        },
        {
            title: 'Kirish',
            dataIndex: 'address',
            key: 'address',
            width:'7%',
            render: (_, record) => (
                <>
                    <Button onClick={()=>{setDocumentTitle(record.name)
                        navigate(`/task-list/${record.id}`)}} type='primary' size={"large"}>
                        Kirish
                    </Button>
                </>
            ),
        }
    ];
    const  getSubjects = () => {
        axios.get(`https://api-lms.usat.uz/api/student/content/topic/get_all/?content_id=${id}&group_id=${sessionStorage.getItem('groupId')}`)
            .then((res) => {
                setSubjects(res.data.results)
            })
            .catch(() => {
            });
    }
    useEffect(() => {
        setDocumentTitle('Topshirirqlar')
        getSubjects()
    }, []);
    return (
        <div className='resources-list'>
            <Table columns={columns} pagination={false} dataSource={subjects}/>
        </div>
    );
};

export default Task;