import React, {useEffect, useState} from 'react';
import {Button, Form, Modal, Table} from "antd";
import axios from "axios";
import {useParams} from "react-router-dom";
import {CloudDownloadOutlined, InboxOutlined} from "@ant-design/icons";
import TextArea from "antd/es/input/TextArea";
import Dragger from "antd/es/upload/Dragger";
import {toast, ToastContainer} from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';
import { FaLock } from "react-icons/fa";
import { FaLockOpen } from "react-icons/fa";
import './Task-list.scss'


const TaskList = () => {
    const {id} = useParams()
    const [tasks, setTasks] = useState([])
    const [uploaderFile,setUploadedFile] = useState([])
    const [isModalOpen,setIsModalOpen] = useState(false)
    const [temp,setTemp] = useState({})
    const [file,setFile]= useState(null)
    const [form] = Form.useForm();
    const [loading,setLoading] = useState(false)
    const [fileList, setFileList] = useState([]);
    const [loadingDow,setLoadingDow] = useState({
        id:'',
        loading:false
    })
    const expandedRowRender = (record) => {
        const filesForTask = uploaderFile.filter((file) => file.task_id === record.id);
        const columns = [
            {
                title: 'Izoh',
                dataIndex: 'comment',
                key: 'comment',
            },
            {
                title: 'Yuklangan vaqt',
                dataIndex: 'file_date_sending',
                key: 'file_date_sending',
                width:'13%',
                render: (_,record) =>(
                    <p>{formatter(record.file_date_sending)}</p>
                ),
            },
            {
                title: 'Yuklangan file',
                dataIndex: 'file_date_sending',
                key: 'file_date_sending',
                width:"9%",
                render: (_,record) =>(
                    <Button style={{width:'100%'}} onClick={()=>download(record.student_file)} type='primary'><CloudDownloadOutlined /></Button>
                ),
            },

        ];

        const data = filesForTask.map((file) => ({
            key: file.id,
            number: file.comment,
            student_file: file.student_file,
            file_date_sending: file.file_date_sending,
            comment: file.comment,
        }));

        return <Table columns={columns} dataSource={data} pagination={false} />;
    };
    const columns = [
        {
            title: '',
            render:(_,record)=>(
                <>
                    {!record?.is_status? <FaLock />:<FaLockOpen />}
                </>
    ),
            width: '5%'

        },
        {
            title: 'Tartibi',
            dataIndex: 'number',
            key: 'number',
            width: "5%"
        },
        {
            title: 'Name',
            dataIndex: 'name',
            key: 'name',
            render: (_,record) =>(
                <p>{record.tasks_id.name}</p>
            ),
        },
        {
            title: 'Izoh',
            dataIndex: 'platform',
            key: 'platform',
            width:'12%',
            render: (_,record) =>(
                <p>{record.tasks_id.comment}</p>
            ),
        },
        {
            title: 'Boshlanish',
            dataIndex: 'version',
            key: 'version',
            width:'12%',
            render: (_,record) =>(
                <p>{formatter(record.tasks_id.start_date)}</p>
            ),
        },
        {
            title: 'Tugash',
            dataIndex: 'upgradeNum',
            key: 'upgradeNum',
            width:'12%',
            render: (_,record) =>(
                <p>{formatter(record.tasks_id.end_date)}</p>
            ),
        },
        {
            title: 'Urunishlar soni',
            dataIndex: 'upgradeNum',
            key: 'upgradeNum',
            width:'8%',
            render: (_,record) =>(
                <p>{record.tasks_id.attempts}</p>
            ),
        },
        {
            title: 'Topshiriq fayli',
            dataIndex: 'creator',
            key: 'creator',
            width:'9%',
            render: (_,record) =>(
                <Button style={{width:'100%'}} loading={loadingDow.id === record.id&&loadingDow.loading} disabled={!record.is_status} onClick={()=>downloadAll(record)} type='primary'><CloudDownloadOutlined /></Button>
            ),
        },
        {
            title: 'File yuklash',
            dataIndex: 'creator',
            key: 'creator',
            width:'5%',
            render: (_,record) =>(
                <Button style={{width:'100%'}} className={(record.is_status&&record.is_passed)||(!record.is_status&&!record.is_passed)?'':"allow"} disabled={!record.time_status||!record.is_status||record.is_passed} onClick={()=>openModal(record)} type='primary'>Yuklash</Button>
            ),
        },
        {
            title: 'Max. Ball',
            dataIndex: 'createdAt',
            key: 'createdAt',
            width:'6%',
            render: (_,record) =>(
               <p>{record.tasks_id.score}</p>
            ),
        },
        {
            title: 'Ball',
            key: 'mark',
            width:'4%',
            dataIndex: 'mark',

        },
    ];
    const formatter = (time)=>{
        const isoDate = new Date(time);

        // Format date using toLocaleString
        return  isoDate.toLocaleString('uz-UZ', {
            year: 'numeric',
            month: '2-digit',
            day: '2-digit',
            hour: 'numeric',
            minute: 'numeric',
            second: 'numeric'
        });
    }
    const getTasks = () => {
        axios.get(
            `https://api-lms.usat.uz/api/student/topic/tasks/?topic_id=${id}&student_id=${sessionStorage.getItem('student_uid_id')}`
        )
            .then((res) => {
                let temp =[]
                let tempUpload =[]
                // eslint-disable-next-line array-callback-return
                res.data.results.map((item)=>{
                    // eslint-disable-next-line array-callback-return
                    item.student_task_fayls?.map((file)=>{
                        tempUpload.push({
                            key:file.id,
                            task_id:item.id,
                            ...file
                        })
                    })
                    temp.push({
                        key:item.id,
                        ...item
                    })
                })
                setUploadedFile(tempUpload)
                setTasks(temp)
            })
            .catch(() => {

            });
    }
    const check = () => {
        axios.get(
            `https://api-lms.usat.uz/api/student/task/check/?student_id=${sessionStorage.getItem('student_uid_id')}&topic_id=${id}`
        )
            .then((res) => {

            })
            .catch(() => {

            });
    }
    const checkTime = () => {
        axios.get(
            `https://api-lms.usat.uz/api/student/task/time-check/?student_id=${sessionStorage.getItem('student_uid_id')}`
        )
            .then((res) => {

            })
            .catch(() => {

            });
    }
    const onFinish =(values)=>{
        setLoading(true)
        const formData = new FormData()
        formData.append('comment',values.comment)
        formData.append('student_id', sessionStorage.getItem('student_uid_id'))
        formData.append('student_file', file)
        formData.append('tasks_id', temp.tasks_id.id)
        formData.append('student_connect_task_id',temp.id)
        axios.post('https://api-lms.usat.uz/api/student/task/', formData, {
            headers: {
                'Content-type': 'multipart/form-data'
            }
        }).then((res) => {
            toast.success('Topshiriq muvoffaqiyatli yuborildi', {
                position: toast.POSITION.TOP_RIGHT,
            });
            form.resetFields()
            handleCancel()
            setFile(null)
            setLoading(false)
            getTasks()
        }).catch((err)=>{
            form.resetFields()
            setFile(null)
            handleCancel()
            setLoading(false)
            toast.error(err.response.data.message?err.response.data.message:"Xatolik", {
                position: toast.POSITION.TOP_RIGHT,
            });
        })
    }
   const downloadAll=(files)=>{
        setLoadingDow({
            id:files.id,
            loading: true
        })
       // eslint-disable-next-line array-callback-return
        files.tasks_id.task_files?.map((temp)=>{
            download(temp.task_file)
        })
    }
    const download = (file) => {
        axios
            .get(`https://api-lms.usat.uz${file}`, { responseType: 'blob' }) // Set responseType to 'blob'
            .then((res) => {
                handleDownloadResponse(res, file);
            })
            .catch((err) => {
                console.error(err);
            });
    };

    const handleDownloadResponse = (response, file) => {
        const blob = new Blob([response.data], {
            type: response.headers['content-type'],
        });
        const link = document.createElement('a');
        link.href = window.URL.createObjectURL(blob);
        const filename = 'Topshiriq';
        link.download = filename;
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
        window.URL.revokeObjectURL(link.href);
        setLoadingDow({
            id:1,
            loading: false
        })
    };
    const handleCancel = ()=>{
        setIsModalOpen(false)
    }
    const openModal = (id) =>{
        setIsModalOpen(true)
        setTemp(id)
    }
    const handleChange = (info) => {
        let files1 = [...info.fileList];
        const fileSizeLimit = 5 * 1024 * 1024;
        files1 = files1.filter(file => file.size <= fileSizeLimit);
        setFileList(files1);
        if (!(info?.file?.size / 1024 / 1024 < 5)) {
            toast.error('File hajmi 5mb oshishi mumkin emas')
        } else {
            console.log(info.file)
          setFile(info?.file)
        }
    }
    useEffect(() => {
        checkTime()
        check()
        getTasks()
    }, []);

    return (
        <div>
        <ToastContainer/>
            <Modal title="Topshiriqqa javob berish" centered='true' open={isModalOpen} footer={false} onCancel={handleCancel}>
                <Form
                    form={form}
                    layout='vertical'
                    name="basic"
                    labelCol={{
                        span: 8,
                    }}
                    style={{
                        maxWidth: 600,
                    }}
                    initialValues={{
                        remember: true,
                    }}
                    onFinish={onFinish}
                    // onFinishFailed={onFinishFailed}
                    autoComplete="off"
                >
                    <Form.Item
                        label="Fayl"
                        name="username"
                    >
                        <Dragger
                            fileList={fileList}
                            beforeUpload={() => false}
                            multiple={false}
                            maxCount={1}
                            onChange={handleChange}
                        >
                            <p className="ant-upload-drag-icon">
                                <InboxOutlined />
                            </p>
                            <p className="ant-upload-text">Bosing yoki file tashlang</p>
                            <p className="ant-upload-hint">
                                Maximal fayl kattaligi 5 mb
                            </p>
                        </Dragger>
                    </Form.Item>

                    <Form.Item name='comment' label="Izoh">
                        <TextArea rows={4} />
                    </Form.Item>



                    <Form.Item>
                        <Button  loading={loading} disabled={file===null} style={{width:'100%'}} type="primary" htmlType="submit" size={"large"}>
                            Saqlash
                        </Button>
                    </Form.Item>
                </Form>
            </Modal>
            <Table

                columns={columns}
                expandable={{
                    expandedRowRender,
                    rowExpandable: (record) => uploaderFile.some((file) => file.task_id === record.id)
                }}
                dataSource={tasks}
                pagination={false}
                scroll={{
                    x: 900
                }}
            />
        </div>
    );
};

export default TaskList;