import React, {useEffect, useState} from 'react';
import {useNavigate, useParams} from "react-router-dom";
import {Button, Table,ConfigProvider} from "antd";
import axios from "axios";
import './ResourcesList.scss'
import {useAuth} from "../auth/AuthProvider";
import {setDocumentTitle} from "../Store/DocumentTitle";

const Potoc = () => {
    const [subjects,setSubjects] = useState([])
    const {user,getUser} = useAuth()
    const navigate = useNavigate();
    const access = sessionStorage.getItem('accessC')
    const columns = [
        {
            title: 'Mavzu nomi',
            dataIndex: 'name',
            key: 'name',
        },
        // {
        //     title: 'Xolat',
        //     dataIndex: 'age',
        //     key: 'age',
        //     width:'5%',
        //     render: (_, record) => (
        //         <>
        //             <div className={record.is_active?'active true':'active'}>
        //             </div>
        //         </>
        //     ),
        // },
        {
            title: 'Dars xonaga kirish',
            key: 'tags',
            dataIndex: 'tags',
            width:'8%',
            render: (_, record) => (
                <>
                 <ConfigProvider
      theme={{
        components: {
          Button: {
            colorPrimary: `#28C76F`,
            colorPrimaryHover: `#28C76F`,
            colorPrimaryActive: `#28C76F`,
            lineWidth: 0,
          },
        },
      }}
    >
      <Button disabled={record.team_bigbluebutton?false:true} onClick={()=>enterBBB(record.team_bigbluebutton,record.id)}  type='primary' size={"large"}>
                      Dars xonaga kirish
                  </Button>
    </ConfigProvider>
                 
                </>
            ),
        },
    ];
    const  getSubjects = () => {
        axios.get(`https://api-lms.usat.uz/api/student/lesson_room/`,
            {
                headers: {
                    'Authorization': `Bearer ${access}`
                }
            }
        )
            .then((res) => {
                setSubjects(res.data.results)
            })
            .catch(() => {
            });
    }
    const enterBBB =(id,mid)=>{
        axios.get(`https://api-lms.usat.uz/api/student/lesson_room/bbb/?bbb_id=${id}&group_id=${sessionStorage.getItem('groupId')}&lesson_room=${mid}`).then(()=>{
            join(id)
        }).catch(()=>{

        })
    }
    const join=(id)=>{
        axios.post(`https://api-lms.usat.uz/api/bigbluebutton/join/attendee/`, {
            username: user.full_name,
            id: id
        }).then((res) => {
            window.open(res.data.url)
        }).catch(() => {
            // this.errorNotification("Dars xona mavjud emas")
        })
    }
    const enter=(id)=>{
        navigate(`/uploaded-resources/${id}`)
        localStorage.removeItem('currentVideoId')
        localStorage.removeItem('currentVideoFile')
    }
    useEffect(() => {
        setDocumentTitle('Resurslar')
        getSubjects()
        getUser()
    }, []);
    return (
        <div className='resources-list'>
            <Table scroll={{
                x: 1000
            }} columns={columns} pagination={false} dataSource={subjects}/>
        </div>
    );
};

export default Potoc;